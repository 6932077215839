import React, { useState } from 'react'

function UnderlineEffectText({text, active, handle_click}) {
    const [ hover, setHover ] = useState(false);

    return (
        <div className='relative w-fit h-fit cursor-pointer'
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
        onClick={() => handle_click()}>
            <h2 className="notoser-bold text-xl sm:text-2xl text-orange-primary text-center">{text}</h2>
            <div className={'h-1 bg-orange-primary rounded-full mt-1.5 transition-all duration-500 origin-bottom-left ease-out ' + ((hover || active) ? 'w-full' : 'w-0')}></div>
        </div>
    )
}

export default UnderlineEffectText