export function FormData_to_Obj(formData) {
    // Convert to an object
    const formObject = {};

    formData.forEach((value, key) => {
        // If the key already exists, handle as an array (e.g., checkboxes)
        if (formObject[key]) {
            formObject[key] = [].concat(formObject[key], value);
        } else {
            formObject[key] = value;
        }
    });

    return formObject;
}