import React, { useEffect, useRef, useState } from 'react';
import '../../../../pages/Staff_login/staff_login.css';
import Form_Accordion_Assessment from './Form_Accordion_Assessment';
import Form_Accordion_Result from './Form_Accordion_Result';
import Form_Accordion_DietaryNote from './Form_Accordion_DietaryNote';
import axios from 'axios';
import ModalWaiting from '../../Modal/ModalWaiting/ModalWaiting';
import Adding_food_panel from './Adding_food_panel';
import color_codes from './Color_Code_List';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormData_to_Obj } from './../../../../assets/js/FormData_to_Obj'
import { Empty_Obj_Form_OPD, Empty_Obj_Form_DietaryIntake } from '../Empty_Form_Obj';

const Modal_Edit_NutritionIntake = ({isShow, food_items, edit_item, handle_close, handle_update}) => {
  const color_code_white = '#ffffff';
  const [ theme_color, setTheme_color ] = useState(color_code_white);
  const [ meal_index, setMeal_index ] = useState(-1);

  const ref_food_panel = useRef(null);

  useEffect(() => {
    if(isShow) {
      if(ref_food_panel.current) {
        ref_food_panel.current.refresh();
      }
    }
  }, [isShow]); 

  useEffect(() => {
    setMeal_index(edit_item.meal_index);
  }, [edit_item]);

  useEffect(() => {
    setTheme_color(meal_index === -1 ? color_code_white : color_codes[meal_index]?.color);
  }, [meal_index]);

  return (
    <>
        { /* Modal confirm submit */ }
        <div className={'absolute top-0 left-0 w-full h-full z-50 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
            <div className='w-11/12 h-5/6 bg-white drop-shadow-md rounded-md pb-8 overflow-y-scroll' style={{backgroundColor: theme_color}}>
              <div className='w-full h-fit flex justify-end mt-8'>
                <div className='w-fit h-fit cursor-pointer px-12' onClick={() => {
                  handle_close();
                  
                  if(ref_food_panel.current) {
                    ref_food_panel.current.close();
                  }
                }}>
                    <box-icon name='x'></box-icon>
                </div>
              </div>

              <Adding_food_panel 
              ref={ref_food_panel}
              food_items={food_items}
              edit_item={edit_item}
              handle_meal_change={(meal_index) => {
                setMeal_index(meal_index);
              }}
              handle_custom_height_change={(new_height) => {
                //nothing to do in this component
              }}
              handle_update={(obj) => {
                handle_update(edit_item, obj);
              }}/>
            </div>
        </div>
        
        { /* Modal backdrop */ }
        <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40 ' + (isShow ? 'block' : 'hidden')}></div>
    </>
  )
}

const Form_Submit_Button = (props) => {
  const [ date, setDate ] = useState('');
  const [ date_type, setDate_type ] = useState('N/A');

  const ref_date_type = useRef(null);

  useEffect(() => {
    if(props.handle_update) {
      props.handle_update((date !== '') || (date_type !== 'N/A'));
    }
  }, [date, date_type]);

  useEffect(() => {
    if(props.record_id && props.record_obj) {
      if(props.record_id !== '' && Object.keys(props.record_obj).length > 0) {
        if(props.record_obj['record_date']) {
          setDate(formatDate(props.record_obj['record_date']));
        }

        if(props.record_obj['date_type']) {
          //all option values are string
          const options = Array.from(ref_date_type.current.options).map(option => option.value);
          const options_index = options.indexOf(String(props.record_obj['date_type']));
          ref_date_type.current.selectedIndex = options_index;
        }
      }
    }
  }, [props.record_id, props.record_obj]);

  // Convert the timestamp to YYYY-MM-DD format
  const formatDate = (isoString) => {
    return isoString.split("T")[0]; // Extracts only 'YYYY-MM-DD'
  };

  return (
    <div className='w-full h-fit px-8 md:px-12 pt-2 pb-16 bg-white rounded-md'>
      <div className='w-full h-fit grid grid-cols-2 gap-5'>
        <div className='w-full h-fit'>
          <p className='notoser-reg text-web-black mt-16 mb-4'>กรุณาระบุวันที่บริโภค</p>
          <input name='record_date' id='record_date' type='date' placeholder='กรุณาระบุวันที่บริโภค' value={date}
          className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1 cursor-pointer'
          onChange={(e) => setDate(e.target.value)}></input>
        </div>

        <div className='w-full h-fit'>
          <p className='notoser-reg text-web-black mt-16 mb-4'>กรุณาระบุประเภทของวันที่บริโภค</p>

          <select ref={ref_date_type} name='date_type' id='date_type' 
          className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4' defaultValue={'N/A'}
          onChange={(e) => setDate_type(e.target.value)}>
            <option value={'N/A'}>กรุณาระบุประเภทของวันที่บริโภค...</option>
            <option value={'วันธรรมดา'}>วันธรรมดา</option>
            <option value={'วันหยุด'}>วันหยุด</option>
          </select>
        </div>
      </div>
    </div>
  )
}

const Form_NutritionIntake = () => {
  const [ record_id, setRecord_id ] = useState('');
  const [ valueHN, setValueHN ] = useState('');
  const [ valueAN, setValueAN ] = useState('');
  const [ patient_name, setPatient_name ] = useState('');

  const [ energy_requirement, setEnergy_requirement ] = useState(-1);
  const [ CHO_requirement, setCHO_requirement ] = useState(55);
  const [ PRO_requirement, setPRO_requirement ] = useState(15);
  const [ FAT_requirement, setFAT_requirement ] = useState(30);
  const [ sodium_requirement, setSodium_requirement ] = useState(2000);
  const [ energy_intake, setEnergy_intake ] = useState(0);
  const [ CHO_intake, setCHO_intake ] = useState(0);
  const [ PRO_intake, setPRO_intake ] = useState(0);
  const [ FAT_intake, setFAT_intake ] = useState(0);
  const [ sodium_intake, setSodium_intake ] = useState(0);

  const [ food_items, setFood_items ] = useState([]); //from DB
  const [ food_intake_items, setFood_intake_items ] = useState([]); //from users added for submit
  const [ food_edit_item, setFood_edit_item ] = useState({}); //select from food_intake_items to edit in modal

  const [ loading_screen, setLoading_screen ] = useState(true);
  const [ modal_edit_foodintake, setModal_edit_foodintake ] = useState(false);

  const [ Obj_Form_OPD, setObj_Form_OPD ] = useState({});
  const [ Obj_Form_Dietary_intake, setObj_Form_Dietary_intake ] = useState({});

  const [ active_assessment, setActive_assessment ] = useState(false);
  const [ active_dietary, setActive_dietary ] = useState(false);
  const [ active_calendar, setActive_calendar ] = useState(false);

  const ref_dietary_note = useRef(null);

  const { name } = useParams();
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let abortcontroller = new AbortController();

    axios.get("https://script.google.com/macros/s/AKfycbziwbtvKXDRXEuoaM4CY5iVgEfqaMO4R1r28hzNeylfUtC7zatySHyJq1jJOyuPER9z/exec", { signal: abortcontroller.signal })
    .then((res) => {
      setFood_items([...res.data]);
    })
    .catch((error) => {
      console.log(error);
    });

    return () => abortcontroller.abort();
  }, []);

  useEffect(() => {
    if(location.state) {
      setRecord_id(location.state.record_id || '');
      setValueHN(location.state.HN || '');
      setValueAN(location.state.AN || '');
      setPatient_name(location.state.patient_name || '');
      setObj_Form_OPD(location.state.obj_form_opd || {...Empty_Obj_Form_OPD});
      setObj_Form_Dietary_intake(location.state.obj_form_dietary_intake || {...Empty_Obj_Form_DietaryIntake});
    }
  }, [location]);

  useEffect(() => {
    setRecord_id(id);
  }, [id]);

  useEffect(() => {
    if(Object.keys(Obj_Form_Dietary_intake).length > 0) {
      if(Obj_Form_Dietary_intake.food_item) {
        if(Obj_Form_Dietary_intake.food_item.length > 0) {
          setFood_intake_items([...Obj_Form_Dietary_intake.food_item]);
        }
      }
    }
  }, [Obj_Form_Dietary_intake]);

  useEffect(() => {
    setLoading_screen(food_items.length === 0);
  }, [food_items]);

  const [ dietary_info, setDietary_info ] = useState([
    {
        icon: 'ข้อมูลส่วนตัว',
        type: 'solid',
        name: 'user-circle',
        text_icon: null,
        process: null,
        active: false,
        elementID: 'HN',
        accordionTitleID: null,
    },
    {
        icon: 'การบริโภคอาหาร',
        type: 'solid',
        name: 'bowl-hot',
        text_icon: null,
        process: null,
        active: false,
        elementID: 'check_Med',
        accordionTitleID: 'accordion_other_header',
    },
    {
      icon: 'วันเวลาที่บริโภค',
      type: null,
      name: 'calendar',
      text_icon: null,
      process: null,
      active: false,
      elementID: 'admit',
      accordionTitleID: 'accordion_ccpc_header',
    },
  ]);

  const [ clinical_info, setClinical_info ] = useState([
    {
        icon: 'ฟอร์ม OPD',
        type: null,
        name: 'notepad',
        text_icon: null,
        process: null,
        active: false,
        elementID: null,
        accordionTitleID: null,
    },
  ]);

  useEffect(() => {
    let new_dietary_info = [...dietary_info];
    new_dietary_info[dietary_info.findIndex(x => x.icon === 'ข้อมูลส่วนตัว')].active = active_assessment;
    new_dietary_info[dietary_info.findIndex(x => x.icon === 'การบริโภคอาหาร')].active = active_dietary;
    new_dietary_info[dietary_info.findIndex(x => x.icon === 'วันเวลาที่บริโภค')].active = active_calendar;
    setDietary_info(new_dietary_info);

    const obj_OPD = {...Obj_Form_OPD};
    const obj_empty = {...Empty_Obj_Form_OPD};

    let new_clinical_info = [...clinical_info];
    new_clinical_info[clinical_info.findIndex(x => x.icon === 'ฟอร์ม OPD')].active = (JSON.stringify(obj_OPD) !== JSON.stringify(obj_empty));
    setClinical_info(new_clinical_info);
  }, [active_assessment, active_dietary, active_calendar]);

  const Icon_process = ({description, type, name, text_icon, process, active, elementID, accordionTitleID}) => {
      const [ hover, setHover ] = useState(false);

      const color_primary_orange = '#fe9d6a';
      const color_inactive_gray = '#A9A9A9';
      const color_white = '#FFFFFF';

      function handle_icon_clicked() {
          open_accordion().then(scroll_to_element);

          function open_accordion() {
              return new Promise((resolve) => {
                  if(accordionTitleID !== null) {
                      let accordion_title = document.getElementById(accordionTitleID);
                      if(!accordion_title.classList.contains('rounded-t-md')) { accordion_title.click(); }
                  }
                  resolve();
              })
          }

          function scroll_to_element() {
              return new Promise((resolve) => {
                  setTimeout(() => {
                      if(elementID !== null) {
                          document.getElementById(elementID).scrollIntoView({ behavior: "smooth", block: "center" });
                      }
                  }, 200);
                  resolve();
              })
          }
      }

      return (
          <div className='relative' onClick={() => handle_icon_clicked()}>
              <div className='relative cursor-pointer'
              onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                  <div className={'w-8 h-8 flex justify-center items-center rounded-full'} style={{backgroundColor: hover ? (active ? color_primary_orange : color_inactive_gray) : ''}}>
                      {
                          (text_icon) ? 
                              <p className='notoser-exbold text-xs text-web-black text-center' style={{color: hover ? '#FFFFFF' : (active ? color_primary_orange : color_inactive_gray)}}>{text_icon}</p>
                          :
                              <box-icon type={type ? type : null} name={name} color={hover ? color_white : (active ? color_primary_orange : color_inactive_gray)}></box-icon>
                      }
                  </div>

                  {
                      process ? <p className='notoser-semi text-xs text-center' style={{color: active ? color_primary_orange : color_inactive_gray}}>{process}</p> : null
                  }
              </div>

              <div className={"absolute top-0 -left-1 mb-2 w-max px-2 py-1 bg-gray-700 text-white text-sm rounded-md -translate-x-full " + (hover ? 'block' : 'hidden')}>
                  {description}
              </div>
          </div>
      );
  }
  
  const Icon_process_small = ({type, name, text_icon, process, active, elementID, accordionTitleID}) => {
      const [ hover, setHover ] = useState(false);

      const color_primary_orange = '#fe9d6a';
      const color_inactive_gray = '#A9A9A9';
      const color_white = '#FFFFFF';

      function handle_icon_clicked() {
          open_accordion().then(scroll_to_element);

          function open_accordion() {
              return new Promise((resolve) => {
                  if(accordionTitleID !== null) {
                      let accordion_title = document.getElementById(accordionTitleID);
                      if(!accordion_title.classList.contains('rounded-t-md')) { accordion_title.click(); }
                  }
                  resolve();
              })
          }

          function scroll_to_element() {
              return new Promise((resolve) => {
                  setTimeout(() => {
                      if(elementID !== null) {
                          document.getElementById(elementID).scrollIntoView({ behavior: "smooth", block: "center" });
                      }
                  }, 200);
                  resolve();
              })
          }
      }

      return (
          <div className='flex gap-1 cursor-pointer' onClick={() => handle_icon_clicked()} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
              <div className='w-6 h-6 flex justify-center items-center rounded-full' style={{backgroundColor: hover ? (active ? color_primary_orange : color_inactive_gray) : ''}}>
                  {
                      (text_icon) ? 
                          <p className='notoser-exbold text-web-black text-center' style={{color: hover ? '#FFFFFF' : (active ? color_primary_orange : color_inactive_gray), fontSize: '8px'}}>{text_icon}</p>
                      :
                          <div className='w-fit h-6'>
                              <box-icon type={type ? type : null} name={name} color={hover ? color_white : (active ? color_primary_orange : color_inactive_gray)} size='xs'></box-icon>
                          </div>
                  }
              </div>

              {
                  process ? 
                      <div className='w-fit h-6 flex items-center'>
                          <p className='notoser-reg text-xs text-center' style={{color: active ? color_primary_orange : color_inactive_gray, fontSize: '8px'}}>{process}</p>
                      </div>
                  : 
                      null
              }
          </div>
      );
  }

  function redirect_to_OPD_Form() {
    const form = document.querySelector("#dietary_intake"); 
    const formData = new FormData(form);
    const obj = FormData_to_Obj(formData);
    obj.food_item = food_intake_items;

    navigate('/staff/form/opd/add/' + name, {
        replace: true,  // This will clear the history, preventing back navigation
        state: { 
            record_id: record_id,
            HN: valueHN,
            AN: valueAN,
            patient_name: patient_name,
            obj_form_opd: Obj_Form_OPD,
            obj_form_dietary_intake: obj,
        } 
    });
  }

  return (
    <>
      <div className='relative staff_login_container'>
        { /* Mobile icon */ }
        <div className='absolute left-0 top-0 block md:hidden w-full h-fit flex justify-start px-2 my-2 gap-2 z-10 overflow-x-scroll'>
          <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-row px-2 py-1 items-start'}>
              {
                  dietary_info.map((x, i) => {
                      return (
                          <Icon_process_small key={i} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                      );
                  })
              }
          </div>

          <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-row px-2 py-1 items-start'}>
              {
                  clinical_info.map((x, i) => {
                      return (
                        <div onClick={() => redirect_to_OPD_Form()}>
                          <Icon_process_small key={i} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                        </div>
                      );
                  })
              }
          </div>
        </div>

        <div className='absolute left-0 top-10 md:top-0 w-screen h-screen z-10 block md:flex justify-end gap-5'>
          <div className='grow h-screen overflow-y-auto ml-6 mr-6 md:mr-0'>
            <div className='w-full h-fit mt-2 mb-10'>
              <form id="dietary_intake" name="dietary_intake" className='w-full h-fit'
              onSubmit={(e) => e.preventDefault()}>
                <Form_Accordion_Assessment 
                record_id={record_id}
                HN={valueHN}
                AN={valueAN}
                patient_name={patient_name}
                obj_form_dietary={Obj_Form_Dietary_intake}
                handle_update={(energy, CHO, PRO, FAT, sodium, active_icon) => {
                  setEnergy_requirement(energy);
                  setCHO_requirement(CHO);
                  setPRO_requirement(PRO);
                  setFAT_requirement(FAT);
                  setSodium_requirement(sodium);
                  setActive_assessment(active_icon);
                }}/>
                
                <div className='my-4'></div>

                <Form_Accordion_Result
                req_energy={energy_requirement}
                req_CHO={CHO_requirement}
                req_PRO={PRO_requirement}
                req_FAT={FAT_requirement}
                req_sodium={sodium_requirement}
                intake_energy={energy_intake}
                intake_CHO={CHO_intake}
                intake_PRO={PRO_intake}
                intake_FAT={FAT_intake}
                intake_sodium={sodium_intake}/>

                <div className='my-4'></div>

                <Form_Accordion_DietaryNote 
                ref={ref_dietary_note}
                food_items={food_items}
                record_id={record_id}
                record_obj={Obj_Form_Dietary_intake}
                handle_update={(nutrients, foods_list) => {
                  setEnergy_intake(nutrients.energy);
                  setCHO_intake(nutrients.CHO);
                  setPRO_intake(nutrients.PRO);
                  setFAT_intake(nutrients.FAT);
                  setSodium_intake(nutrients.sodium);
                  
                  setFood_intake_items([...foods_list]);
                  setActive_dietary(foods_list.length > 0);
                }}
                handle_edit={(index) => {
                  setFood_edit_item(food_intake_items[index]);
                  setModal_edit_foodintake(true);
                }}/>

                <div className='my-4'></div>

                <Form_Submit_Button
                record_id={record_id}
                record_obj={Obj_Form_Dietary_intake}
                handle_update={(active_calendar_icon) => {
                  console.log(active_calendar_icon);
                  setActive_calendar(active_calendar_icon);
                }}/>

                <input name='energy_requirement' type="hidden" value={energy_requirement}></input>
                <input name='CHO_requirement' type="hidden" value={CHO_requirement}></input>
                <input name='PRO_requirement' type="hidden" value={PRO_requirement}></input>
                <input name='FAT_requirement' type="hidden" value={FAT_requirement}></input>
                <input name='sodium_requirement' type="hidden" value={sodium_requirement}></input>
                <input name='energy_intake' type="hidden" value={energy_intake}></input>
                <input name='CHO_intake' type="hidden" value={CHO_intake}></input>
                <input name='PRO_intake' type="hidden" value={PRO_intake}></input>
                <input name='FAT_intake' type="hidden" value={FAT_intake}></input>
                <input name='sodium_intake' type="hidden" value={sodium_intake}></input>
              </form>
            </div>
          </div>

          <div className='hidden md:block w-fit min-w-fit h-screen z-10 mr-6 mt-2 overflow-visible'>
              <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-col px-2 py-2 my-2'}>
                  {
                      dietary_info.map((x, i) => {
                          return (
                              <Icon_process key={i} description={x.icon} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                          );
                      })
                  }
              </div>

              <div className={'w-fit h-fit flex gap-1 rounded-full bg-white flex-col px-2 py-2 my-2'}>
                  {
                      clinical_info.map((x, i) => {
                          return (
                              <div onClick={() => redirect_to_OPD_Form()}>
                                <Icon_process key={i} description={x.icon} type={x.type} name={x.name} text_icon={x.text_icon} process={x.process} active={x.active} elementID={x.elementID} accordionTitleID={x.accordionTitleID}/>
                              </div>
                            );
                      })
                  }
              </div>
          </div>
        </div>

        <div className='absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0'></div>
      </div>

      {/* Any Modal */}
      <ModalWaiting isShow={loading_screen}/>
      <Modal_Edit_NutritionIntake 
      isShow={modal_edit_foodintake} 
      food_items={food_items} 
      edit_item={food_edit_item}
      handle_close={() => setModal_edit_foodintake(false)}
      handle_update={(prev_obj, new_obj) => {
        ref_dietary_note.current.update_list_in_table(prev_obj, new_obj);
        setFood_edit_item({});
        setModal_edit_foodintake(false);
      }}/> { /* clear edit_food_index and modal_edit_foodintake after using this modal*/ }
    </>
  )
}

export default Form_NutritionIntake