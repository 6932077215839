import React, { useEffect, useRef, useState } from 'react';
import Form_Accordion_Result_Table from './Form_Accordion_Result_Table';

const Form_Accordion_Result = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
  }, [isOpen]);

  return (
    <div className='my-4'>
      <button type='button' className={"w-full px-8 py-4 text-left border-b border-gray-300 flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
          <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>ข้อมูลผู้ป่วย</h3>
      </button>

      <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: height }}>
        <div className='w-full h-fit px-8 md:px-12 pt-8 pb-16 bg-white'>
          <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start mb-8'>ผลลัพธ์พลังงานและสารอาหาร</h3>
          
          <Form_Accordion_Result_Table
          req_energy={props.req_energy}
          req_CHO={props.req_CHO}
          req_PRO={props.req_PRO}
          req_FAT={props.req_FAT}
          req_sodium={props.req_sodium}
          intake_energy={props.intake_energy}
          intake_CHO={props.intake_CHO}
          intake_PRO={props.intake_PRO}
          intake_FAT={props.intake_FAT}
          intake_sodium={props.intake_sodium}/>
        </div>
      </div>
    </div>
  )
}

export default Form_Accordion_Result