export const Empty_Obj_Form_OPD = {
    "HN": "",
    "AN": "",
    "ชื่อนามสกุล": "",
    "select_physician": "ไม่ระบุ",
    "แพทย์": "",
    "admit": "",
    "dayIPD": "",
    "visit_day2": "",
    "visit_day5": "",
    "discharge": "",
    "Date_AS0": "",
    "ลักษณะอาหาร_AS0": "ไม่ระบุ",
    "คะแนนความเข้าใจ0_ก่อน": "",
    "คะแนนพฤติกรรม0_ก่อน": "",
    "คะแนนความเข้าใจ0_หลัง": "",
    "คะแนนพฤติกรรม0_หลัง": "",
    "Date_AS1": "",
    "ลักษณะอาหาร_AS1": "ไม่ระบุ",
    "คะแนนความเข้าใจ1_ก่อน": "",
    "คะแนนพฤติกรรม1_ก่อน": "",
    "คะแนนความเข้าใจ1_หลัง": "",
    "คะแนนพฤติกรรม1_หลัง": "",
    "Date_AS2": "",
    "ลักษณะอาหาร_AS2": "ไม่ระบุ",
    "คะแนนความเข้าใจ2_ก่อน": "",
    "คะแนนพฤติกรรม2_ก่อน": "",
    "คะแนนความเข้าใจ2_หลัง": "",
    "คะแนนพฤติกรรม2_หลัง": "",
    "ผ่านเกณฑ์ความเข้าใจ_AS": "ไม่ระบุ",
    "ผ่านเกณฑ์พฤติกรรม_AS": "ไม่ระบุ",
    "ผ่านเกณฑ์_THT": "ไม่ระบุ",
    "พฤติกรรมข้อ1วันที่1_oral": "default",
    "พฤติกรรมข้อ1วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ1วันที่1_oral": "default",
    "ความเข้าใจข้อ1วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ2วันที่1_oral": "default",
    "พฤติกรรมข้อ2วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ2วันที่1_oral": "default",
    "ความเข้าใจข้อ2วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ3วันที่1_oral": "default",
    "พฤติกรรมข้อ3วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ3วันที่1_oral": "default",
    "ความเข้าใจข้อ3วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ4วันที่1_oral": "default",
    "พฤติกรรมข้อ4วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ4วันที่1_oral": "default",
    "ความเข้าใจข้อ4วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ5วันที่1_oral": "default",
    "พฤติกรรมข้อ5วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ5วันที่1_oral": "default",
    "ความเข้าใจข้อ5วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ6วันที่1_oral": "default",
    "พฤติกรรมข้อ6วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ6วันที่1_oral": "default",
    "ความเข้าใจข้อ6วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ7วันที่1_oral": "default",
    "พฤติกรรมข้อ7วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ7วันที่1_oral": "default",
    "ความเข้าใจข้อ7วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ8วันที่1_oral": "default",
    "พฤติกรรมข้อ8วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ8วันที่1_oral": "default",
    "ความเข้าใจข้อ8วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ9วันที่1_oral": "default",
    "พฤติกรรมข้อ9วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ9วันที่1_oral": "default",
    "ความเข้าใจข้อ9วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ10วันที่1_oral": "default",
    "พฤติกรรมข้อ10วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ10วันที่1_oral": "default",
    "ความเข้าใจข้อ10วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ11วันที่1_oral": "default",
    "พฤติกรรมข้อ11วันที่1_oral_ก่อน": "default",
    "ความเข้าใจข้อ11วันที่1_oral": "default",
    "ความเข้าใจข้อ11วันที่1_oral_ก่อน": "default",
    "พฤติกรรมข้อ1วันที่1_ons": "default",
    "พฤติกรรมข้อ1วันที่1_ons_ก่อน": "default",
    "ความเข้าใจข้อ1วันที่1_ons": "default",
    "ความเข้าใจข้อ1วันที่1_ons_ก่อน": "default",
    "พฤติกรรมข้อ2วันที่1_ons": "default",
    "พฤติกรรมข้อ2วันที่1_ons_ก่อน": "default",
    "ความเข้าใจข้อ2วันที่1_ons": "default",
    "ความเข้าใจข้อ2วันที่1_ons_ก่อน": "default",
    "พฤติกรรมข้อ3วันที่1_ons": "default",
    "พฤติกรรมข้อ3วันที่1_ons_ก่อน": "default",
    "ความเข้าใจข้อ3วันที่1_ons": "default",
    "ความเข้าใจข้อ3วันที่1_ons_ก่อน": "default",
    "พฤติกรรมข้อ1วันที่2_oral": "default",
    "พฤติกรรมข้อ1วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ1วันที่2_oral": "default",
    "ความเข้าใจข้อ1วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ2วันที่2_oral": "default",
    "พฤติกรรมข้อ2วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ2วันที่2_oral": "default",
    "ความเข้าใจข้อ2วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ3วันที่2_oral": "default",
    "พฤติกรรมข้อ3วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ3วันที่2_oral": "default",
    "ความเข้าใจข้อ3วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ4วันที่2_oral": "default",
    "พฤติกรรมข้อ4วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ4วันที่2_oral": "default",
    "ความเข้าใจข้อ4วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ5วันที่2_oral": "default",
    "พฤติกรรมข้อ5วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ5วันที่2_oral": "default",
    "ความเข้าใจข้อ5วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ6วันที่2_oral": "default",
    "พฤติกรรมข้อ6วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ6วันที่2_oral": "default",
    "ความเข้าใจข้อ6วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ7วันที่2_oral": "default",
    "พฤติกรรมข้อ7วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ7วันที่2_oral": "default",
    "ความเข้าใจข้อ7วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ8วันที่2_oral": "default",
    "พฤติกรรมข้อ8วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ8วันที่2_oral": "default",
    "ความเข้าใจข้อ8วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ9วันที่2_oral": "default",
    "พฤติกรรมข้อ9วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ9วันที่2_oral": "default",
    "ความเข้าใจข้อ9วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ10วันที่2_oral": "default",
    "พฤติกรรมข้อ10วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ10วันที่2_oral": "default",
    "ความเข้าใจข้อ10วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ11วันที่2_oral": "default",
    "พฤติกรรมข้อ11วันที่2_oral_ก่อน": "default",
    "ความเข้าใจข้อ11วันที่2_oral": "default",
    "ความเข้าใจข้อ11วันที่2_oral_ก่อน": "default",
    "พฤติกรรมข้อ1วันที่2_ons": "default",
    "พฤติกรรมข้อ1วันที่2_ons_ก่อน": "default",
    "ความเข้าใจข้อ1วันที่2_ons": "default",
    "ความเข้าใจข้อ1วันที่2_ons_ก่อน": "default",
    "พฤติกรรมข้อ2วันที่2_ons": "default",
    "พฤติกรรมข้อ2วันที่2_ons_ก่อน": "default",
    "ความเข้าใจข้อ2วันที่2_ons": "default",
    "ความเข้าใจข้อ2วันที่2_ons_ก่อน": "default",
    "พฤติกรรมข้อ3วันที่2_ons": "default",
    "พฤติกรรมข้อ3วันที่2_ons_ก่อน": "default",
    "ความเข้าใจข้อ3วันที่2_ons": "default",
    "ความเข้าใจข้อ3วันที่2_ons_ก่อน": "default",
    "พฤติกรรมข้อ1วันที่3_oral": "default",
    "พฤติกรรมข้อ1วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ1วันที่3_oral": "default",
    "ความเข้าใจข้อ1วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ2วันที่3_oral": "default",
    "พฤติกรรมข้อ2วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ2วันที่3_oral": "default",
    "ความเข้าใจข้อ2วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ3วันที่3_oral": "default",
    "พฤติกรรมข้อ3วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ3วันที่3_oral": "default",
    "ความเข้าใจข้อ3วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ4วันที่3_oral": "default",
    "พฤติกรรมข้อ4วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ4วันที่3_oral": "default",
    "ความเข้าใจข้อ4วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ5วันที่3_oral": "default",
    "พฤติกรรมข้อ5วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ5วันที่3_oral": "default",
    "ความเข้าใจข้อ5วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ6วันที่3_oral": "default",
    "พฤติกรรมข้อ6วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ6วันที่3_oral": "default",
    "ความเข้าใจข้อ6วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ7วันที่3_oral": "default",
    "พฤติกรรมข้อ7วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ7วันที่3_oral": "default",
    "ความเข้าใจข้อ7วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ8วันที่3_oral": "default",
    "พฤติกรรมข้อ8วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ8วันที่3_oral": "default",
    "ความเข้าใจข้อ8วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ9วันที่3_oral": "default",
    "พฤติกรรมข้อ9วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ9วันที่3_oral": "default",
    "ความเข้าใจข้อ9วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ10วันที่3_oral": "default",
    "พฤติกรรมข้อ10วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ10วันที่3_oral": "default",
    "ความเข้าใจข้อ10วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ11วันที่3_oral": "default",
    "พฤติกรรมข้อ11วันที่3_oral_ก่อน": "default",
    "ความเข้าใจข้อ11วันที่3_oral": "default",
    "ความเข้าใจข้อ11วันที่3_oral_ก่อน": "default",
    "พฤติกรรมข้อ1วันที่3_ons": "default",
    "พฤติกรรมข้อ1วันที่3_ons_ก่อน": "default",
    "ความเข้าใจข้อ1วันที่3_ons": "default",
    "ความเข้าใจข้อ1วันที่3_ons_ก่อน": "default",
    "พฤติกรรมข้อ2วันที่3_ons": "default",
    "พฤติกรรมข้อ2วันที่3_ons_ก่อน": "default",
    "ความเข้าใจข้อ2วันที่3_ons": "default",
    "ความเข้าใจข้อ2วันที่3_ons_ก่อน": "default",
    "พฤติกรรมข้อ3วันที่3_ons": "default",
    "พฤติกรรมข้อ3วันที่3_ons_ก่อน": "default",
    "ความเข้าใจข้อ3วันที่3_ons": "default",
    "ความเข้าใจข้อ3วันที่3_ons_ก่อน": "default",
    "F/U": "ไม่ระบุ",
    "SOC_IPD_Date": "",
    "SOC_IPD": "ไม่ระบุ",
    "SOC_OPD1_Date": "",
    "SOC_OPD1": "ไม่ระบุ",
    "SOC_OPD2_Date": "",
    "SOC_OPD2": "ไม่ระบุ",
    "SOC_ผ่าน": "ไม่ระบุ",
    "LDL_IPD_Date": "",
    "LDL_IPD": "",
    "LDL_OPD1_Date": "",
    "LDL_OPD1": "",
    "LDL_OPD2_Date": "",
    "LDL_OPD2": "",
    "LDL_ผ่าน": "N/A",
    "ccpc_select_diagnosis": "ไม่ระบุ",
    "CCPC_goal": "",
    "HbA1C_Date1": "",
    "HbA1C1": "",
    "HbA1C_Date2": "",
    "HbA1C2": "",
    "HbA1C_Date3": "",
    "HbA1C3": "",
    "dm_select_diagnosis": "ไม่ระบุ",
    "DM_goal": "",
    "ติดตาม_DM": "ไม่ระบุ",
    "other_select_diagnosis": "ไม่ระบุ",
    "Other_goal": "",
    "ติดตาม_other": "ไม่ระบุ",
    "ACS": "",
    "HF": "",
    "Stroke": "",
    "TKA": "",
    "TBI": "",
    "CCPC_other": "",
    "CCPC_diagnosis": "",
    "new_DM": "",
    "poor_DM": "",
    "GDM": "",
    "DLP": "",
    "ลดน้ำหนัก": "",
    "osteoporosis": "",
    "DM_other": "",
    "DM_diagnosis": "",
    "Med": "",
    "Onco": "",
    "ไตเทียม": "",
    "เด็ก": "",
    "Neuro": "",
    "Cardio": "",
    "GI": "",
    "Ortho": "",
    "Other_other": "",
    "ผู้ป่วยติดต่อทางโทรศัพท์": "",
    "Other_diagnosis": "",
    "ผู้ประเมิน": ""
}

export const Empty_Obj_Form_DietaryIntake = {
    'age': '',
    'weight': '',
    'height': '',
    'isMale': false,
    'simple_value': -1,
    'activity': 'ไม่นำมาคำนวณ (PAL 1.0)',
    'activity_value': 1,
    'energy_equation': 'โปรดระบุสมการคำนวณพลังงาน...',
    'energy_requirement_index': 0,
    'stress': 1,
    'pregnancy': 'ไม่มีครรภ์ / ไม่ใช่ช่วงให้นมบุตร',
    'percentCHO': 55,
    'percentPRO': 15,
    'percentFAT': 30,
    'energy_requirement': -1,
    'CHO_requirement': 55,
    'PRO_requirement': 15,
    'FAT_requirement': 30,
    'sodium_requirement': 2000,
    'energy_intake': 0,
    'CHO_intake': 0,
    'PRO_intake': 0,
    'FAT_intake': 0,
    'sodium_intake': 0,
    'food_item': [],
    'record_date': '',
    'date_type': 'N/A',
    'active': false,
}