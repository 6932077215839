import React, { useEffect, useRef, useState } from 'react';
import ModalWaiting from '../../Modal/ModalWaiting/ModalWaiting';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import logo from '../../../../assets/logo/logo.jpg';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";

import url_list from '../../../../url/url_list';
import UnderlineEffectText from '../../UnderlineEffectText/UnderlineEffectText';
import color_codes from '../Form_NutritionIntake/Color_Code_List';

//import Form_
import { ER_options } from '../Form_NutritionIntake/Options_Energy_Equation';
import { Empty_Obj_Form_DietaryIntake } from '../Empty_Form_Obj';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import Swiper_Navigation from '../../Swiper/Swiper_Navigation';
import { PAL_options } from '../Form_NutritionIntake/Options_PAL';
import Form_Accordion_Result_Table from '../Form_NutritionIntake/Form_Accordion_Result_Table';

const Form_Display = ({detail, detail_dietary}) => {
    const [ is_opd, setIs_opd ] = useState(true);
    const [ is_dietary, setIs_dietary ] = useState(false);

    const ref_followup = useRef(null);
    const ref_behavior = useRef(null);
    const ref_SOC = useRef(null);
    const ref_LDL = useRef(null);
    const ref_HbA1C = useRef(null);
    const ref_swiper = useRef(null);

    const Section = ({section}) => {
        return (
            <div className='w-full h-fit flex gap-3 mt-12 mb-6'>
                <div className='w-2 h-auto rounded-full bg-orange-primary'></div>
                <h3 className='notoser-bold text-xl sm:text-3xl text-orange-primary text-center pt-3'>{section}</h3>
            </div>
        )
    }

    const List_with_correct_sign = ({label, checked}) => {
        return (
            <div className='w-full h-fit flex gap-3 justify-start items-start'>
                <div className={'w-4 h-4 rounded-full ' + (checked ? 'bg-orange-primary' : 'bg-gray-300')}></div>
                <p className={(checked ? 'notoser-semi text-orange-primary' : 'notoser-reg text-gray-400')}>{label}</p>
            </div>
        )
    }

    const Seperator = () => {
        return (
            <div className='w-full h-fit flex justify-center my-14'>
                <hr className='w-3/4'/>
            </div>
        )
    }

    function convertToThaiDate(dateString) {
        if(dateString === '') {
            return '-';
        }

        // Parse the input date string
        const date = new Date(dateString);
    
        // Define Thai month names
        const thaiMonths = [
            "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
            "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
        ];
    
        // Get the day, month, and year
        const day = date.getDate();
        const month = thaiMonths[date.getMonth()];
        const year = date.getFullYear() + 543; // Convert to Thai Buddhist year
    
        // Return the formatted Thai date
        return `${day} ${month} ${year}`;
    }

    function getResult_Behavior(result) {
        if(parseInt(result) === 0) {
            return 'ไม่ผ่าน (0)';
        }
        else if(parseInt(result) === 1) {
            return 'ผ่าน (1)';
        }
        else {
            return result;
        }
    }

    function getResult_Followup(result) {
        if(parseInt(result) === 0) {
            return 'ไม่ติดตาม (0)';
        }
        else if(parseInt(result) === 1) {
            return 'ติดตาม (1)';
        }
        else {
            return result;
        }
    }

    function getResult_SOC(result) {
        if(parseInt(result) === 0) {
            return 'Precontemplation';
        }
        else if(parseInt(result) === 1) {
            return 'Contemplation';
        }
        else if(parseInt(result) === 2) {
            return 'Preparation';
        }
        else if(parseInt(result) === 3) {
            return 'Action';
        }
        else if(parseInt(result) === 4) {
            return 'Maintenance';
        }
        else {
            return result;
        }
    }

    function getResult_LDL(result) {
        if(parseInt(result) === 0) {
            return 'ไม่ผ่าน';
        }
        else if(parseInt(result) === 1) {
            return 'ผ่าน';
        }
        else {
            return result;
        }
    }

    function getSwiper_Index(index, max_index) {
        if(index < 0) {
            return 0;
        }
        else if(index > max_index) {
            return max_index;
        }
        else {
            return index;
        }
    }

    const Preview_OPD = ({detail}) => {
        return (
            <>
                <Section section={'กลุ่มโรค CCPC'}/>

                <p className='notoser-bold text-web-black text-lg'>ภาวะทางการแพทย์</p>
                <div className='w-full h-fit grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-4'>
                    <List_with_correct_sign label={'Acute Coronary Syndrome (ACS)'} checked={parseInt(detail.ACS) === 1}/>
                    <List_with_correct_sign label={'Heart Failure (HF)'} checked={parseInt(detail.HF) === 1}/>
                    <List_with_correct_sign label={'Acute Ischemic Stroke/ Hemorrhage/ TIA (Stroke)'} checked={parseInt(detail.Stroke) === 1}/>
                    <List_with_correct_sign label={'Total Knee Arthroplasty (TKA)'} checked={parseInt(detail.TKA) === 1}/>
                    <List_with_correct_sign label={'Traumatic Brain Injury (TBI)'} checked={parseInt(detail.TBI) === 1}/>
                    <List_with_correct_sign label={'อื่นๆ'} checked={parseInt(detail.CCPC_other) === 1}/>
                </div>

                <p className='text-web-black mt-6 mb-2'>
                    <span className='notoser-reg'>ภาวะทางการแพทย์อื่นๆ (ถ้ามี) : </span>
                    <span className='notoser-reg'>{(detail.CCPC_other_specify === '') ? '-' : detail.CCPC_other_specify}</span>
                </p>

                <p className='notoser-reg text-web-black mt-8'>วันที่เข้ารับและสิ้นสุดการรักษา</p>
                <div className='relative w-full h-fit'>
                    <div className='absolute top-1/2 -translate-y-1/2 left-0 w-fit h-fit z-10'>
                        <Swiper_Navigation boxicon_name={'chevron-left'} handle_onclick={() => {
                            const index = ref_followup.current.swiper.activeIndex;
                            ref_followup?.current?.swiper?.slideToLoop(getSwiper_Index(index - 1, 5));
                        }}/>
                    </div>

                    <Swiper
                    ref={ref_followup}
                    className='w-full h-fit mt-4 mb-10'
                    spaceBetween={20}
                    slidesPerView={1}
                    breakpoints={{
                        1050: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        680: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Pagination]}>
                        <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                            <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วันที่เข้ารับการรักษา (Admit) :</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.admit)}</p>
                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>1 / 5</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                            <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วัน Follow up (IPD) :</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.dayIPD)}</p>
                            <p className='notoser-semi text-web-black text-center text-xs  mt-4'>2 / 5</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                            <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วันที่เข้าเยี่ยม วันที่ 2 (day2) :</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.visit_day2)}</p>
                            <p className='notoser-semi text-web-black text-center text-xs  mt-4'>3 / 5</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                            <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วันที่เข้าเยี่ยม วันที่ 5 (day5) :</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.visit_day5)}</p>
                            <p className='notoser-semi text-web-black text-center text-xs  mt-4'>4 / 5</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                            <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วันที่สิ้นสุดการรักษา (Discharge) :</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.discharge)}</p>
                            <p className='notoser-semi text-web-black text-center text-xs  mt-4'>5 / 5</p>
                        </SwiperSlide>
                    </Swiper>

                    <div className='absolute top-1/2 -translate-y-1/2 right-0 w-fit h-fit z-10'>
                        <Swiper_Navigation boxicon_name={'chevron-right'} handle_onclick={() => {
                            const index = ref_followup.current.swiper.activeIndex;
                            ref_followup?.current?.swiper?.slideToLoop(getSwiper_Index(index + 1, 5));
                        }}/>
                    </div>
                </div>
                
                <p className='notoser-reg text-web-black mt-8'>คะแนนการปรับพฤติกรรม (เฉพาะ ACS, Stroke)</p>
                <div className='relative w-full h-fit'>
                    <div className='absolute top-1/2 -translate-y-1/2 left-0 w-fit h-fit z-10'>
                        <Swiper_Navigation boxicon_name={'chevron-left'} handle_onclick={() => {
                            const index = ref_behavior.current.swiper.activeIndex;
                            ref_behavior?.current?.swiper?.slideToLoop(getSwiper_Index(index - 1, 3));
                        }}/>
                    </div>

                    <Swiper
                    ref={ref_behavior}
                    className='w-full h-fit mt-4 mb-10'
                    spaceBetween={20}
                    slidesPerView={1}
                    breakpoints={{
                        1250: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        850: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Pagination]}
                    >
                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>การประเมินก่อน consult (IPD)</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.Date_AS0)}</p>

                            <p className='notoser-reg text-web-black mt-4 mb-2'>ลักษณะอาหาร : {detail.ลักษณะอาหาร_AS0}</p>

                            <p className='notoser-reg text-orange-primary mt-4 mb-2'><b>คะแนนพฤติกรรม (ก่อน)</b></p>
                            <div className='grid grid-cols-2 justify-start gap-5'>
                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนความเข้าใจ0_ก่อน} คะแนน</p>
                                </div>

                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนพฤติกรรม0_ก่อน} คะแนน</p>
                                </div>
                            </div>

                            <p className='notoser-reg text-orange-primary mt-4 mb-2'><b>คะแนนพฤติกรรม (หลัง)</b></p>
                            <div className='grid grid-cols-2 justify-start gap-5'>
                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนความเข้าใจ0_หลัง} คะแนน</p>
                                </div>

                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนพฤติกรรม0_หลัง} คะแนน</p>
                                </div>
                            </div>

                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>1 / 3</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>หลัง consult ครั้งที่ 1</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.Date_AS1)}</p>

                            <p className='notoser-reg text-web-black mt-4 mb-2'>ลักษณะอาหาร : {detail.ลักษณะอาหาร_AS0}</p>

                            <p className='notoser-reg text-orange-primary mt-4 mb-2'><b>คะแนนพฤติกรรม (ก่อน)</b></p>
                            
                            <div className='grid grid-cols-2 justify-start gap-5'>
                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนความเข้าใจ1_ก่อน} คะแนน</p>
                                </div>

                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนพฤติกรรม1_ก่อน} คะแนน</p>
                                </div>
                            </div>

                            <p className='notoser-reg text-orange-primary mt-4 mb-2'><b>คะแนนพฤติกรรม (หลัง)</b></p>
                            
                            <div className='grid grid-cols-2 justify-start gap-5'>
                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนความเข้าใจ1_หลัง} คะแนน</p>
                                </div>

                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนพฤติกรรม1_หลัง} คะแนน</p>
                                </div>
                            </div>

                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>2 / 3</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>หลัง consult ครั้งที่ 2</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.Date_AS2)}</p>

                            <p className='notoser-reg text-web-black mt-4 mb-2'>ลักษณะอาหาร : {detail.ลักษณะอาหาร_AS0}</p>

                            <p className='notoser-reg text-orange-primary mt-4 mb-2'><b>คะแนนพฤติกรรม (ก่อน)</b></p>
                            
                            <div className='grid grid-cols-2 justify-start gap-5'>
                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนความเข้าใจ2_ก่อน} คะแนน</p>
                                </div>

                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนพฤติกรรม2_ก่อน} คะแนน</p>
                                </div>
                            </div>

                            <p className='notoser-reg text-orange-primary mt-4 mb-2'><b>คะแนนพฤติกรรม (หลัง)</b></p>
                            
                            <div className='grid grid-cols-2 justify-start gap-5'>
                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนความเข้าใจ2_หลัง} คะแนน</p>
                                </div>

                                <div className='grow h-fit'>
                                    <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                                    <p className='notoser-reg text-web-black line-clamp-1'>{detail.คะแนนพฤติกรรม2_หลัง} คะแนน</p>
                                </div>
                            </div>

                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>3 / 3</p>
                        </SwiperSlide>
                    </Swiper>

                    <div className='absolute top-1/2 -translate-y-1/2 right-0 w-fit h-fit z-10'>
                        <Swiper_Navigation boxicon_name={'chevron-right'} handle_onclick={() => {
                            const index = ref_behavior.current.swiper.activeIndex;
                            ref_behavior?.current?.swiper?.slideToLoop(getSwiper_Index(index + 1, 3));
                        }}/>
                    </div>
                </div>

                <p className='text-web-black mt-6 mb-2'>
                    <span className='notoser-reg'>การแปลผลความเข้าใจ (สำหรับ ACS, Stroke) : </span>
                    <span className='notoser-semi text-orange-primary'>{getResult_Behavior(detail.ผ่านเกณฑ์ความเข้าใจ_AS)}</span>
                </p>

                <p className='text-web-black mt-6 mb-2'>
                    <span className='notoser-reg'>การแปลผลพฤติกรรม (สำหรับ ACS, Stroke) : </span>
                    <span className='notoser-semi text-orange-primary'>{getResult_Behavior(detail.ผ่านเกณฑ์พฤติกรรม_AS)}</span>
                </p>

                <p className='text-web-black mt-6 mb-2'>
                    <span className='notoser-reg'>การแปลผล (สำหรับ TKA, HF, TBI) : </span>
                    <span className='notoser-semi text-orange-primary'>{getResult_Behavior(detail.ผ่านเกณฑ์_THT)}</span>
                </p>

                <p className='text-web-black mt-6 mb-2'>
                    <span className='notoser-reg'>การติดตาม : </span>
                    <span className='notoser-semi text-orange-primary'>{getResult_Followup(detail["F/U"])} </span>
                    <span className='notoser-reg'>(ติดตามวันที่ {convertToThaiDate(detail["วัน_F/U"])})</span>
                </p>

                <p className='notoser-bold text-web-black text-lg mt-8'>Stage of Change</p>

                <div className='relative w-full h-fit'>
                    <div className='absolute top-1/2 -translate-y-1/2 left-0 w-fit h-fit z-10'>
                        <Swiper_Navigation boxicon_name={'chevron-left'} handle_onclick={() => {
                            const index = ref_SOC.current.swiper.activeIndex;
                            ref_SOC?.current?.swiper?.slideToLoop(getSwiper_Index(index - 1, 3));
                        }}/>
                    </div>

                    <Swiper
                    ref={ref_SOC}
                    className='w-full h-fit mt-4 mb-10'
                    spaceBetween={20}
                    slidesPerView={1}
                    breakpoints={{
                        1050: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        750: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Pagination]}
                    >
                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>การประเมินที่ IPD</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.SOC_IPD_Date)}</p>
                            
                            <p className='notoser-reg text-web-black mt-4 mb-2'>ผลการประเมิน :</p>
                            <p className='line-clamp-1'>
                                <span className='notoser-semi text-orange-primary'>{getResult_SOC(detail.SOC_IPD)}</span> 
                                
                                {
                                    (detail.SOC_IPD === 'ไม่ระบุ') ?  null : <span className='notoser-reg text-web-black'> ({detail.SOC_IPD} คะแนน)</span>
                                }
                            </p>

                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>1 / 3</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>การประเมินหลัง consult ครั้งที่ 1</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.SOC_OPD1_Date)}</p>
                            
                            <p className='notoser-reg text-web-black mt-4 mb-2'>ผลการประเมิน :</p>
                            <p className='line-clamp-1'>
                                <span className='notoser-semi text-orange-primary'>{getResult_SOC(detail.SOC_OPD1)}</span> 

                                {
                                    (detail.SOC_OPD1 === 'ไม่ระบุ') ?  null : <span className='notoser-reg text-web-black'> ({detail.SOC_OPD1} คะแนน)</span>
                                }
                            </p>

                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>2 / 3</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>การประเมินหลัง consult ครั้งที่ 2</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.SOC_OPD2_Date)}</p>
                            
                            <p className='notoser-reg text-web-black mt-4 mb-2'>ผลการประเมิน :</p>
                            <p className='line-clamp-1'>
                                <span className='notoser-semi text-orange-primary'>{getResult_SOC(detail.SOC_OPD2)}</span> 

                                {
                                    (detail.SOC_OPD2 === 'ไม่ระบุ') ?  null : <span className='notoser-reg text-web-black'> ({detail.SOC_OPD2} คะแนน)</span>
                                }
                            </p>

                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>3 / 3</p>
                        </SwiperSlide>
                    </Swiper>

                    <div className='absolute top-1/2 -translate-y-1/2 right-0 w-fit h-fit z-10 hover:translate-x-2/3 transition diration-300'>
                        <Swiper_Navigation boxicon_name={'chevron-right'} handle_onclick={() => {
                            const index = ref_SOC.current.swiper.activeIndex;
                            ref_SOC?.current?.swiper?.slideToLoop(getSwiper_Index(index + 1, 3));
                        }}/>
                    </div>
                </div>

                <p className='notoser-bold text-web-black text-lg mt-8'>ผลการตรวจระดับ LDL (เฉพาะ ACS, Stroke)</p>
                <div className='relative w-full h-fit'>
                    <div className='absolute top-1/2 -translate-y-1/2 left-0 w-fit h-fit z-10'>
                        <Swiper_Navigation boxicon_name={'chevron-left'} handle_onclick={() => {
                            const index = ref_LDL.current.swiper.activeIndex;
                            ref_LDL?.current?.swiper?.slideToLoop(getSwiper_Index(index - 1, 3));
                        }}/>
                    </div>

                    <Swiper
                    ref={ref_LDL}
                    className='w-full h-fit mt-4 mb-10'
                    spaceBetween={20}
                    slidesPerView={1}
                    breakpoints={{
                        1050: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        750: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Pagination]}
                    >
                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>การประเมินก่อน consult</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.LDL_IPD_Date)}</p>
                            <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ LDL : {detail.LDL_IPD} mmol/L</p>
                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>1 / 3</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>การประเมินหลัง consult ครั้งที่ 1</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.LDL_OPD1_Date)}</p>
                            <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ LDL : {detail.LDL_OPD1} mmol/L</p>
                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>2 / 3</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>การประเมินหลัง consult ครั้งที่ 2</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.LDL_OPD2_Date)}</p>
                            <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ LDL : {detail.LDL_OPD2} mmol/L</p>
                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>3 / 3</p>
                        </SwiperSlide>
                    </Swiper>

                    <div className='absolute top-1/2 -translate-y-1/2 right-0 w-fit h-fit z-10'>
                        <Swiper_Navigation boxicon_name={'chevron-right'} handle_onclick={() => {
                            const index = ref_LDL.current.swiper.activeIndex;
                            ref_LDL?.current?.swiper?.slideToLoop(getSwiper_Index(index + 1, 3));
                        }}/>
                    </div>
                </div>

                <p className='text-web-black mt-6 mb-2'>
                    <span className='notoser-reg'>การแปลผล LDL : </span>
                    <span className='notoser-semi text-orange-primary'>{getResult_LDL(detail.LDL_ผ่าน)}</span>
                </p>

                <p className='notoser-bold text-web-black text-lg mt-8'>Nutritional Diagnosis</p>
                <p className='notoser-semi text-orange-primary my-2'>{detail.CCPC_diagnosis} <span className={(detail.CCPC_diagnosis_specify === '') ? 'hidden' : 'incline-block'}>({detail.CCPC_diagnosis_specify})</span></p>

                <p className='notoser-bold text-web-black text-lg mt-8'>เป้าหมายการให้โภชนบำบัด</p>
                <p className='notoser-reg text-web-black my-2'>{detail.CCPC_goal}</p>

                <Seperator/>

                <Section section={'กลุ่มโรคเบาหวาน'}/>
                <p className='notoser-bold text-web-black text-lg'>ภาวะทางการแพทย์</p>
                <div className='w-full h-fit grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 mt-4'>
                    <List_with_correct_sign label={'New Diabetes Mellitus'} checked={parseInt(detail.new_DM) === 1}/>
                    <List_with_correct_sign label={'Diabetes Mellitus (poor control)'} checked={parseInt(detail.poor_DM) === 1}/>
                    <List_with_correct_sign label={'Gestational Diabetes Mellitus'} checked={parseInt(detail.GDM) === 1}/>
                    <List_with_correct_sign label={'Dyslipidemia'} checked={parseInt(detail.DLP) === 1}/>
                    <List_with_correct_sign label={'ลดน้ำหนัก'} checked={parseInt(detail.ลดน้ำหนัก) === 1}/>
                    <List_with_correct_sign label={'Osteoporosis'} checked={parseInt(detail.osteoporosis) === 1}/>
                    <List_with_correct_sign label={'อื่นๆ'} checked={parseInt(detail.DM_other) === 1}/>
                </div>

                <p className='text-web-black mt-6 mb-2'>
                    <span className='notoser-reg'>ภาวะทางการแพทย์อื่นๆ (ถ้ามี) : </span>
                    <span className='notoser-reg'>{(detail.DM_other_specify === '') ? '-' : detail.DM_other_specify}</span>
                </p>

                <p className='notoser-bold text-web-black text-lg mt-8'>ผลตรวจระดับ HbA1C (%)</p>
                <div className='relative w-full h-fit'>
                    <div className='absolute top-1/2 -translate-y-1/2 left-0 w-fit h-fit z-10'>
                        <Swiper_Navigation boxicon_name={'chevron-left'} handle_onclick={() => {
                            const index = ref_HbA1C.current.swiper.activeIndex;
                            ref_HbA1C?.current?.swiper?.slideToLoop(getSwiper_Index(index - 1, 3));
                        }}/>
                    </div>

                    <Swiper
                    ref={ref_HbA1C}
                    className='w-full h-fit mt-4 mb-10'
                    spaceBetween={20}
                    slidesPerView={1}
                    breakpoints={{
                        1050: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        750: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                    }}
                    modules={[Pagination]}
                    >
                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>ครั้งที่ 1</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.HbA1C_Date1)}</p>
                            <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ HbA1C : {detail.HbA1C1} %</p>
                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>1 / 3</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>ครั้งที่ 2</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.HbA1C_Date2)}</p>
                            <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ HbA1C : {detail.HbA1C2} %</p>
                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>2 / 3</p>
                        </SwiperSlide>

                        <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                            <p className='notoser-reg text-web-black mb-4'>ครั้งที่ 3</p>
                            <p className='notoser-reg text-web-black line-clamp-1'>{convertToThaiDate(detail.HbA1C_Date3)}</p>
                            <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ HbA1C : {detail.HbA1C3} %</p>
                            <p className='notoser-semi text-web-black text-center text-xs mt-4'>3 / 3</p>
                        </SwiperSlide>
                    </Swiper>

                    <div className='absolute top-1/2 -translate-y-1/2 right-0 w-fit h-fit z-10'>
                        <Swiper_Navigation boxicon_name={'chevron-right'} handle_onclick={() => {
                            const index = ref_HbA1C.current.swiper.activeIndex;
                            ref_HbA1C?.current?.swiper?.slideToLoop(getSwiper_Index(index + 1, 3));
                        }}/>
                    </div>
                </div>

                <p className='notoser-bold text-web-black text-lg mt-8'>Nutritional Diagnosis</p>
                <p className='notoser-semi text-orange-primary my-2'>{detail.DM_diagnosis} <span className={(detail.DM_diagnosis_specify === '') ? 'hidden' : 'incline-block'}>({detail.DM_diagnosis_specify})</span></p>

                <p className='notoser-bold text-web-black text-lg mt-8'>เป้าหมายการให้โภชนบำบัด</p>
                <p className='notoser-reg text-web-black my-2'>{detail.DM_goal}</p>

                <p className='notoser-bold text-web-black text-lg mt-8'>การติดตาม</p>
                <p className='text-web-black my-2'>
                    <span className='notoser-reg'>การติดตาม : </span>
                    <span className='notoser-semi text-orange-primary'>{getResult_Followup(detail["ติดตาม_DM"])} </span>
                    <span className='notoser-reg'>(ติดตามวันที่ {convertToThaiDate(detail["วันFU_DM"])})</span>
                </p>

                <Seperator/>

                <Section section={'กลุ่มโรคอื่นๆ'}/>

                <p className='notoser-bold text-web-black text-lg'>ภาวะทางการแพทย์</p>
                <div className='w-full h-fit grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 mt-4'>
                    <List_with_correct_sign label={'Med'} checked={parseInt(detail.Med) === 1}/>
                    <List_with_correct_sign label={'Onco'} checked={parseInt(detail.Onco) === 1}/>
                    <List_with_correct_sign label={'ไตเทียม'} checked={parseInt(detail.ไตเทียม) === 1}/>
                    <List_with_correct_sign label={'เด็ก'} checked={parseInt(detail.เด็ก) === 1}/>
                    <List_with_correct_sign label={'Neuro'} checked={parseInt(detail.Neuro) === 1}/>
                    <List_with_correct_sign label={'Cardio'} checked={parseInt(detail.Cardio) === 1}/>
                    <List_with_correct_sign label={'GI'} checked={parseInt(detail.GI) === 1}/>
                    <List_with_correct_sign label={'Ortho'} checked={parseInt(detail.Ortho) === 1}/>
                    <List_with_correct_sign label={'อื่นๆ'} checked={parseInt(detail.Other_other) === 1}/>
                    <List_with_correct_sign label={'ผู้ป่วยติดต่อทางโทรศัพท์'} checked={parseInt(detail.ผู้ป่วยติดต่อทางโทรศัพท์) === 1}/>
                </div>

                <p className='text-web-black mt-6 mb-2'>
                    <span className='notoser-reg'>ภาวะทางการแพทย์อื่นๆ (ถ้ามี) : </span>
                    <span className='notoser-reg'>{(detail.Other_other_specify === '') ? '-' : detail.Other_other_specify}</span>
                </p>

                <p className='notoser-bold text-web-black text-lg mt-8'>Nutritional Diagnosis</p>
                <p className='notoser-semi text-orange-primary my-2'>{detail.Other_diagnosis} <span className={(detail.Other_diagnosis_specify === '') ? 'hidden' : 'incline-block'}>({detail.Other_diagnosis_specify})</span></p>

                <p className='notoser-bold text-web-black text-lg mt-8'>เป้าหมายการให้โภชนบำบัด</p>
                <p className='notoser-reg text-web-black my-2'>{detail.Other_goal}</p>

                <p className='notoser-bold text-web-black text-lg mt-8'>การติดตาม</p>
                <p className='text-web-black my-2'>
                    <span className='notoser-reg'>การติดตาม : </span>
                    <span className='notoser-semi text-orange-primary'>{getResult_Followup(detail["ติดตาม_other"])} </span>
                    <span className='notoser-reg'>(ติดตามวันที่ {convertToThaiDate(detail["วันFU_other"])})</span>
                </p>
            </>
        )
    }

    const Preview_Dietary = ({detail_dietary}) => {
        const [ energy_equation, setEnergy_equation ] = useState('');
        const [ simple_value, setSimple_value ] = useState(Empty_Obj_Form_DietaryIntake['simple_value']);
        const [ PAL, setPAL ] = useState('');
        const [ requirement_energy, setRequirement_energy ] = useState(Empty_Obj_Form_DietaryIntake['energy_requirement']);
        const [ requirement_CHO, setRequirement_CHO ] = useState(Empty_Obj_Form_DietaryIntake['CHO_requirement']);
        const [ requirement_PRO, setRequirement_PRO ] = useState(Empty_Obj_Form_DietaryIntake['PRO_requirement']);
        const [ requirement_FAT, setRequirement_FAT ] = useState(Empty_Obj_Form_DietaryIntake['FAT_requirement']);
        const [ energy_intake, setEnergy_intake ] = useState(Empty_Obj_Form_DietaryIntake['energy_intake']);
        const [ CHO_intake, setCHO_intake ] = useState(Empty_Obj_Form_DietaryIntake['CHO_intake']);
        const [ PRO_intake, setPRO_intake ] = useState(Empty_Obj_Form_DietaryIntake['PRO_intake']);
        const [ FAT_intake, setFAT_intake ] = useState(Empty_Obj_Form_DietaryIntake['FAT_intake']);
        const [ sodium_intake, setSodium_intake ] = useState(Empty_Obj_Form_DietaryIntake['sodium_intake']);
        const [ food_intake_items, setFood_intake_items ] = useState([]);

        useEffect(() => {
            if(Object.keys(detail_dietary).length > 0) {
                if(detail_dietary['dietary_Energy_Equation']) {
                    setEnergy_equation(detail_dietary['dietary_Energy_Equation']);
                }

                if(detail_dietary['dietary_SimpleEquation_value']) {
                    setSimple_value(parseInt(detail_dietary['dietary_SimpleEquation_value']));
                }

                if(detail_dietary['dietary_Activity']) {
                    setPAL(detail_dietary['dietary_Activity']);
                }

                if(detail_dietary['dietary_Energy_Requirement']) {
                    setRequirement_energy(parseFloat(detail_dietary['dietary_Energy_Requirement']));
                }

                if(detail_dietary['dietary_CHO_Requirement']) {
                    setRequirement_CHO(parseFloat(detail_dietary['dietary_CHO_Requirement']));
                }

                if(detail_dietary['dietary_PRO_Requirement']) {
                    setRequirement_PRO(parseFloat(detail_dietary['dietary_PRO_Requirement']));
                }

                if(detail_dietary['dietary_FAT_Requirement']) {
                    setRequirement_FAT(parseFloat(detail_dietary['dietary_FAT_Requirement']));
                }

                if(detail_dietary['dietary_Energy_Intake']) {
                    setEnergy_intake(parseFloat(detail_dietary['dietary_Energy_Intake']));
                }

                if(detail_dietary['dietary_CHO_Intake']) {
                    setCHO_intake(parseFloat(detail_dietary['dietary_CHO_Intake']));
                }

                if(detail_dietary['dietary_PRO_Intake']) {
                    setPRO_intake(parseFloat(detail_dietary['dietary_PRO_Intake']));
                }

                if(detail_dietary['dietary_FAT_Intake']) {
                    setFAT_intake(parseFloat(detail_dietary['dietary_FAT_Intake']));
                }

                if(detail_dietary['dietary_Sodium_Intake']) {
                    setSodium_intake(parseFloat(detail_dietary['dietary_Sodium_Intake']));
                }

                if(detail_dietary['dietary_FoodItem']) {
                    const items = JSON.parse(detail_dietary['dietary_FoodItem']);
                    let unique_food_names = [];
                    let result = [];

                    if(items.length > 0) {
                        for(let i = 0; i < items.length; i++) {
                            const food_name = items[i]['food_name'];
                            
                            if(unique_food_names.indexOf(food_name) === -1) {
                                unique_food_names.push(food_name);
                            }
                        }
                    }

                    if(unique_food_names.length > 0 && items.length > 0) {
                        for(let i = 1; i < color_codes.length; i++) { //index 0 is Please Select...
                            for(let j = 0; j < unique_food_names.length; j++) {
                                const food_name = unique_food_names[j];
                                let meal = '';
                                let source = '';
                                let energy_intake = 0;
                                let CHO_intake = 0;
                                let PRO_intake = 0;
                                let FAT_intake = 0;
                                let sodium_intake = 0;

                                for(let k = 0; k < items.length; k++) {
                                    if(parseInt(items[k]['meal_index']) === i && items[k]['food_name'] === food_name) {
                                        meal = color_codes[i]['meal_th'];
                                        source = items[k]['source'];
                                        energy_intake += parseFloat(items[k]['energy']) * parseFloat(items[k]['food_amount']);
                                        CHO_intake += parseFloat(items[k]['CHO']) * parseFloat(items[k]['food_amount']);
                                        PRO_intake += parseFloat(items[k]['PRO']) * parseFloat(items[k]['food_amount']);
                                        FAT_intake += parseFloat(items[k]['FAT']) * parseFloat(items[k]['food_amount']);
                                        sodium_intake += parseFloat(items[k]['sodium']) * parseFloat(items[k]['food_amount']);
                                    }
                                }

                                if(meal !== '') {
                                    result.push({
                                        food_name: food_name,
                                        meal: meal,
                                        source: source,
                                        energy_intake: Math.round(energy_intake * 10) / 10,
                                        CHO_intake: Math.round(CHO_intake * 10) / 10,
                                        PRO_intake: Math.round(PRO_intake * 10) / 10,
                                        FAT_intake: Math.round(FAT_intake * 10) / 10,
                                        sodium_intake: Math.round(sodium_intake * 10) / 10,
                                    });
                                }
                            }
                        }
                    }

                    let total_energy = 0;
                    let total_CHO = 0; 
                    let total_PRO = 0;
                    let total_FAT = 0;
                    let total_sodium = 0; 

                    for(let i = 0; i < result.length; i++) {
                        total_energy += parseFloat(result[i]['energy_intake']);
                        total_CHO += parseFloat(result[i]['CHO_intake']);
                        total_PRO += parseFloat(result[i]['PRO_intake']);
                        total_FAT += parseFloat(result[i]['FAT_intake']);
                        total_sodium += parseFloat(result[i]['sodium_intake']);
                    }

                    result.push({
                        food_name: 'รวม',
                        meal: '',
                        source: '',
                        energy_intake: Math.round(total_energy * 10) / 10,
                        CHO_intake: Math.round(total_CHO * 10) / 10,
                        PRO_intake: Math.round(total_PRO * 10) / 10,
                        FAT_intake: Math.round(total_FAT * 10) / 10,
                        sodium_intake: Math.round(total_sodium * 10) / 10,
                    });

                    setFood_intake_items([...result]);
                }
            }
        }, [detail_dietary]);

        return (
            <>
                <Section section={'ข้อมูลทั่วไป'}/>

                <div className='w-full h-fit grid grid-cols-3'>
                    <p className='notoser-reg text-web-black my-2'>อายุ: {detail_dietary['dietary_Age']} ปี</p>
                    <p className='notoser-reg text-web-black my-2'>น้ำหนัก: {detail_dietary['dietary_Weight']} kg</p>
                    <p className='notoser-reg text-web-black my-2'>ส่วนสูง: {detail_dietary['dietary_Height']} cm</p>
                </div>

                <p className='notoser-reg text-web-black my-2'>เพศ: {detail_dietary['dietary_Sex']}</p>
                <p className='notoser-reg text-web-black my-2'>สมการคำนวณพลังงาน: {energy_equation} {(energy_equation === 'Simple equation') ? ('(' + simple_value + ' kcal/kg)') : ''}</p>
                <p className='notoser-reg text-web-black my-2'>ระดับกิจกรรมทางกาย (PAL): {PAL}</p>
                <p className='notoser-reg text-web-black my-2'>ระยะตั้งครรภ์ / ช่วงให้นมบุตร: {detail_dietary['dietary_Pregnancy']}</p>

                <Section section={'ผลลัพธ์พลังงานและสารอาหาร'}/>

                <Form_Accordion_Result_Table
                req_energy={requirement_energy}
                req_CHO={requirement_CHO}
                req_PRO={requirement_PRO}
                req_FAT={requirement_FAT}
                req_sodium={2000}
                intake_energy={energy_intake}
                intake_CHO={CHO_intake}
                intake_PRO={PRO_intake}
                intake_FAT={FAT_intake}
                intake_sodium={sodium_intake}/>

                <Section section={'ข้อมูลการบริโภคอาหาร'}/>

                <table className="table-auto w-full h-fit border-separate rounded-md py-4 overflow-x-scroll">
                    <thead>
                        <tr className='w-full h-fit border border-gray-300 border-separate'>
                            <th></th>
                            <th>มื้อ</th>
                            <th>แหล่งที่มา</th>
                            <th>พลังงาน (kcal)</th>
                            <th>คาร์โบไฮเดรต (g)</th>
                            <th>โปรตีน (g)</th>
                            <th>ไขมัน (g)</th>
                            <th>โซเดียม (mg)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (food_intake_items.length === 1) ?
                                <tr>
                                    <td colSpan={8}><span className='w-full text-center line-clamp-1 my-3'>No data</span></td>
                                </tr>
                            :
                                food_intake_items.map((x, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className='pl-4'><span className='w-full line-clamp-1'>{x.food_name}</span></td>
                                            <td><span className='w-full text-center line-clamp-1'>{x.meal}</span></td>
                                            <td><span className='w-full text-center line-clamp-1'>{x.source}</span></td>
                                            <td><span className='w-full text-center line-clamp-1'>{x.energy_intake}</span></td>
                                            <td><span className='w-full text-center line-clamp-1'>{x.CHO_intake}</span></td>
                                            <td><span className='w-full text-center line-clamp-1'>{x.PRO_intake}</span></td>
                                            <td><span className='w-full text-center line-clamp-1'>{x.FAT_intake}</span></td>
                                            <td><span className='w-full text-center line-clamp-1'>{x.sodium_intake}</span></td>
                                        </tr>
                                    )
                                })
                        }
                    </tbody>
                </table>

                <Section section={'วันที่บริโภคอาหาร'}/>
                <p className='notoser-reg text-web-black my-2'>วันที่บริโภคอาหาร: {detail_dietary['dietary_Record_date']}</p>
                <p className='notoser-reg text-web-black my-2'>ประเภทของวันที่บริโภค: {detail_dietary['dietary_Date_type']}</p>
            </>
        )
    }

    return (
        <div className='w-full h-fit bg-white drop-shadow-md rounded-md pt-14 pb-28 px-8 sm:px-14 my-10'>
            <div className='w-full h-fit flex justify-center mt-4 mb-2'>
                <img src={logo} className='w-20 h-auto' />
            </div>

            <h3 className='notoser-bold text-xl sm:text-3xl text-orange-primary text-center mt-6 mb-4'>ข้อมูลการประเมิน OPD</h3>
            <p className='notoser-med text-center'><i>แบบฟอร์มบันทึกสถิติ OPD</i></p>
            <p className='notoser-med text-center'><i>หน่วยโภชนาการ โรงพยาบาลพญาไท 2</i></p>

            <p className='notoser-bold text-web-black text-lg'>ระบุชื่อ-นามสกุลของผู้ประเมิน</p>
            <p className='notoser-reg text-web-black my-2'>{detail.ผู้ประเมิน} (ประเมิน ณ วันที่ {detail.วันที่ประเมิน})</p>

            <p className='notoser-bold text-web-black text-lg mt-8'>ระบุชื่อ-นามสกุลของผู้ป่วย</p>
            <div className='w-full h-fit grid grid-cols-3'>
                <p className='notoser-reg text-web-black my-2'>คุณ {detail.ชื่อนามสกุล}</p>
                <p className='notoser-reg text-web-black my-2'>HN: {detail.HN}</p>
                <p className='notoser-reg text-web-black my-2'>AN: {detail.AN}</p>
            </div>
            <p className='notoser-reg text-web-black'>แพทย์ผู้รับผิดชอบ: {detail.แพทย์}</p>

            <Seperator/>

            <div className='w-full h-fit flex gap-12 justify-center'>
                <UnderlineEffectText text={'ข้อมูล OPD'}
                active={is_opd}
                handle_click={() => {
                    setIs_opd(true);
                    setIs_dietary(false);

                    if(ref_swiper.current) {
                        ref_swiper.current.swiper?.slideTo(0); //0 is slide index of opd
                    }
                }}/>

                <UnderlineEffectText text={'ข้อมูลอาหาร'}
                active={is_dietary}
                handle_click={() => {
                    setIs_opd(false);
                    setIs_dietary(true);

                    if(ref_swiper.current) {
                        ref_swiper.current.swiper?.slideTo(1); //0 is slide index of dietary
                    }
                }}/>
            </div>

            <Swiper
                ref={ref_swiper}
                className='w-full h-fit'
                slidesPerView={1}
                spaceBetween={0}
                modules={[Pagination]}
            >
                <SwiperSlide>
                    <Preview_OPD detail={detail}/>
                </SwiperSlide>

                <SwiperSlide>
                    <Preview_Dietary detail_dietary={detail_dietary}/>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

const Modal_record_preview = ({isShow, detail, detail_dietary, handle_cancel, handle_submit}) => {
    const [ recordID, setRecordID ] = useState('');

    useEffect(() => {
        if(detail_dietary.dietary_record_id) {
            setRecordID(detail_dietary['dietary_record_id']);
        }
    }, [detail_dietary]);

    useEffect(() => {
        setRecordID(''); //recordID is used to decide if close and confirm button will be showed. So, reset it once close the modal
    }, [isShow]);

    return (
        <>
            { /* Modal confirm submit */ }
            <div className={'absolute top-0 left-0 w-full h-full z-30 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
                <div className='relative w-11/12 2xl:w-8/12 h-full overflow-y-scroll'>
                    <Form_Display detail={detail} detail_dietary={detail_dietary}/>
                </div>
            </div>

            { /* Modal backdrop */ }
            <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-20 ' + (isShow ? 'block' : 'hidden')}></div>

            <div className={'absolute bottom-2 left-0 w-full h-fit z-40 flex justify-center ' + (isShow ? 'block ' : 'hidden ') + ((recordID === '') ? 'invisible' : 'visible')}>
                <div className='w-4/5 lg:w-3/5 h-full bg-white rounded-full border border-gray-300 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-5 py-6 px-16 shadow-md'>
                    <button className='w-full h-fit border border-gray-300 rounded-full py-1.5 bg-white hover:bg-zinc-700 hover:text-white transition-color duration-300 mt-0 sm:mt-2 sm:mt-0'
                    onClick={handle_cancel}>ปิด</button>
                    
                    <button className='w-full h-fit border border-orange-primary rounded-full py-1.5 bg-orange-primary hover:bg-orange-bold text-white transition-color duration-300 order-first sm:order-last'
                    onClick={() => handle_submit(recordID)}>แก้ไขการประเมินนี้</button>
                </div>
            </div>
        </>
    )
}

const Form_OPD_dashboard = () => {
    const [ loading_screen, setLoading_screen ] = useState(false);
    const [ res_main, setRes_main ] = useState({});
    const [ res_behav, setRes_behav ] = useState({});
    const [ res_dietary, setRes_dietary ] = useState({});

    const [ OPD_list, setOPD_list ] = useState([]);
    const [ show_modal_preview, setShow_modal_preview ] = useState(false);
    const [ detail_modal_preview, setDetail_modal_preview ] = useState({});
    const [ detail_modal_preview_dietary, setDetail_modal_preview_dietary ] = useState({});
    const [ record_id, setRecord_id ] = useState('');

    const { name } = useParams();
    const navigate = useNavigate();

    const [ url_form_to_edit, setUrl_form_to_edit ] = useState('/staff/form/opd/add/' + name); //we cannot use useNavigate to nevigate with new tab, click hidden <Link> instead
    const ref_navigate = useRef();

    useEffect(() => {
        let abortcontroller = new AbortController();

        if(name === null || name === undefined) {
            navigate("/staff", { replace: true });
        }
        else {
            setLoading_screen(true);

            axios.get(url_list.url_add_and_load_OPD_record, { signal: abortcontroller.signal })
            .then((response) => {
                console.log(response.data);

                if(response.data.result && (response.data.result === 'success')) {
                    if(response.data.message) {
                        const data = JSON.parse(response.data.message);

                        if(data.length > 0) {
                            setOPD_list([...data]);
                            setFiltered_record_OPD([...data]);
                            setLoading_screen(false);
                        }
                    }
                }
                else {
                    alert('โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง')
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }

        return () => abortcontroller.abort();
    }, []);

    useEffect(() => {
        if(record_id !== '') {
            setLoading_screen(true);
            setRes_main({});
            setRes_behav({});
            setRes_dietary({});

            let abortcontroller = new AbortController();

            const fetchData = async () => {
                try {
                    const requests = [
                        axios.get(url_list.url_load_and_update_OPD_record_id, { 
                            params: { id: record_id },
                            signal: abortcontroller.signal 
                        }),
                        /*
                        axios.get(url_list.url_load_behav_record_id, { 
                            params: { id: id },
                            signal: abortcontroller.signal 
                        }),
                        */
                    ];

                    const index_main = 0;
                    const index_behav = 1;
                    const index_dietary = 2;
                    const responses = await Promise.all(requests);

                    setRes_main(responses[0].data[index_main]);
                    setRes_behav(responses[0].data[index_behav]);
                    setRes_dietary(responses[0].data[index_dietary]);
                }
                catch (error) {
                    if (axios.isCancel(error)) {
                        console.log('Request canceled:', error.message);
                    } else {
                        alert('โหลดข้อมูลไม่สำเร็จ โปรดลองกดรีเฟรช หรือเข้าใหม่ในภายหลัง หรือติดต่อผู้พัฒนา');
                        console.error('Error fetching data:', error);
                    }
                }
            };

            fetchData();

            return () => abortcontroller.abort();
        }
    }, [record_id]);

    useEffect(() => {
        if(Object.keys(res_main).length > 0) {
            setDetail_modal_preview({...res_main});
            close_loading_screen_when_finish_load_preview();
        }
    }, [res_main]);
    
    useEffect(() => {
        if(Object.keys(res_behav).length > 0) {
            //nothing to do with behav data
            close_loading_screen_when_finish_load_preview();
        }
    }, [res_behav]);
    
    useEffect(() => {
        if(Object.keys(res_dietary).length > 0) {
            setDetail_modal_preview_dietary({...res_dietary});
            close_loading_screen_when_finish_load_preview();
        }
    }, [res_dietary]);

    useEffect(() => {
        //we cannot use useNavigate to nevigate with new tab, click hidden <Link> instead
        if(ref_navigate.current) {
            ref_navigate.current.click();
        }
    }, [ref_navigate, url_form_to_edit]);

    function close_loading_screen_when_finish_load_preview() {
        //call this function to finish first loading process
        const finish_loading_main = (Object.keys(res_main).length > 0);
        const finish_loading_behav = (Object.keys(res_behav).length > 0);
        const finish_loading_dietary = (Object.keys(res_dietary).length > 0);

        if(finish_loading_main && finish_loading_behav && finish_loading_dietary) {
            setLoading_screen(false);
        }
    }

    function Datatable_filter_input(key, filter_function) {
        return (
          <div className='bg-white border border-gray-200 focus:border-pink-bold focus:outline-pink-bold rounded-md w-full h-10 px-4 pt-1 my-2'>
            <input className='w-full h-full outline-none' 
            placeholder={'ค้นหาด้วย ' + key} 
            onChange={(e) => filter_function(e, key)}/>
          </div>
        )
    }

    const [ filterd_OPD_option, setFiltered_OPD_option ] = useState({ 
        วันที่ประเมิน: '',
        ชื่อนามสกุล: '',
        HN: '',
        ผ่านเกณฑ์ความเข้าใจ_AS: '',
        ผ่านเกณฑ์พฤติกรรม_AS: '',
        ผ่านเกณฑ์_THT: '',
        SOC_ผ่าน: '',
        LDL_ผ่าน: '',
    });

    const [ filterd_record_OPD, setFiltered_record_OPD ] = useState([]);

    const handle_datatable_filter_changed  = async (e, key) => {
        await handle_filter_option_changed(e, key);
        await handle_filter();
    
        function handle_filter_option_changed(e, key) {
          return new Promise((resolve) => {
            let filter_option = filterd_OPD_option;
            filter_option[key] = e.target.value;
            setFiltered_OPD_option(filter_option);
            resolve();
          });
        }
    
        function handle_filter() {
          return new Promise((resolve) => {
            let results = OPD_list.filter(value => {
                return (
                    value["วันที่ประเมิน"].toString().trim().toLowerCase().includes(filterd_OPD_option["วันที่ประเมิน"]) &&
                    value["ชื่อนามสกุล"].toString().trim().toLowerCase().includes(filterd_OPD_option["ชื่อนามสกุล"]) &&
                    value["HN"].toString().trim().toLowerCase().includes(filterd_OPD_option["HN"]) &&
                    value["ผ่านเกณฑ์ความเข้าใจ_AS"].toString().trim().toLowerCase().includes(filterd_OPD_option["ผ่านเกณฑ์ความเข้าใจ_AS"]) &&
                    value["ผ่านเกณฑ์พฤติกรรม_AS"].toString().trim().toLowerCase().includes(filterd_OPD_option["ผ่านเกณฑ์พฤติกรรม_AS"]) &&
                    value["ผ่านเกณฑ์_THT"].toString().trim().toLowerCase().includes(filterd_OPD_option["ผ่านเกณฑ์_THT"]) &&
                    value["SOC_ผ่าน"].toString().trim().toLowerCase().includes(filterd_OPD_option["SOC_ผ่าน"]) &&
                    value["LDL_ผ่าน"].toString().trim().toLowerCase().includes(filterd_OPD_option["LDL_ผ่าน"])
                );
            });
    
            setFiltered_record_OPD(results);
            resolve();
          });
        }
    }

    return (
        <>
            <div className='relative staff_login_container'>
                <div className='absolute left-0 top-0 w-screen h-screen overflow-y-auto z-10'>
                    <div className='mx-0 sm:mx-8 my-8'>
                        <div className='w-full h-fit px-8 md:px-12 py-14 bg-white rounded-none sm:rounded-lg shadow-md drop-shadow-md z-10'>
                            <div className='w-full h-fit border border-gray-300 px-6 py-6 rounded-md'>
                                <h3 className='notoser-bold text-xl sm:text-3xl text-orange-primary text-center mt-6 mb-4'>แบบฟอร์มบันทึกสถิติ OPD</h3>
                                <p className='notoser-med text-web-black text-center'>ผู้ประเมิน: {name}</p>

                                <div className='w-full h-fit block sm:flex flex-row sm:flex-row-reverse justify-center gap-5 mt-8 mb-4'>
                                    <Link to={'/staff/form/opd/add/' + name} target="_blank" rel="noopener noreferrer">
                                        <button className='w-full sm:w-40 h-fit border border-gray-300 rounded-full py-1.5 bg-white hover:bg-zinc-700 hover:text-white transition-color duration-300'>เพิ่มข้อมูลใหม่</button>
                                    </Link>

                                    <Link to={-1}>
                                        <button className='w-full sm:w-40 h-fit border border-gray-300 rounded-full py-1.5 bg-white hover:bg-zinc-700 hover:text-white transition-color duration-300 mt-2 sm:mt-0'>ย้อนกลับ</button>
                                    </Link>
                                </div>
                            </div>

                            <h3 className='notoser-bold text-3xl text-orange-primary mt-10 mb-4'>การประเมินย้อนหลัง</h3>

                            <DataTable 
                            value={filterd_record_OPD} 
                            stripedRows 
                            paginator rows={20}
                            className='w-full h-fit py-6 overflow-y-auto cursor-pointer'
                            globalFilterFields={['วันที่บันทึกผล', 'ผู้ประเมิน', 'โรงอาหาร', 'ชื่อร้านค้า']}
                            filterDisplay="row"
                            onRowClick={(e) => {
                                if(e.data) {
                                    setRecord_id(e.data['record_id']);
                                    setShow_modal_preview(true);
                                }
                            }}>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('วันที่ประเมิน', handle_datatable_filter_changed)} field="วันที่ประเมิน" header="วันที่ประเมิน" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('ชื่อนามสกุล', handle_datatable_filter_changed)} field="ชื่อนามสกุล" header="ชื่อนามสกุล" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('HN', handle_datatable_filter_changed)} field="HN" header="HN" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('ผ่านเกณฑ์ความเข้าใจ_AS', handle_datatable_filter_changed)} field="ผ่านเกณฑ์ความเข้าใจ_AS" header="ผ่านเกณฑ์ความเข้าใจ" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('ผ่านเกณฑ์พฤติกรรม_AS', handle_datatable_filter_changed)} field="ผ่านเกณฑ์พฤติกรรม_AS" header="ผ่านเกณฑ์พฤติกรรม" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('ผ่านเกณฑ์_THT', handle_datatable_filter_changed)} field="ผ่านเกณฑ์_THT" header="ผ่านเกณฑ์_THT" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('SOC_ผ่าน', handle_datatable_filter_changed)} field="SOC_ผ่าน" header="Stage of Change" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('LDL_ผ่าน', handle_datatable_filter_changed)} field="LDL_ผ่าน" header="LDL" sortable></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <Link ref={ref_navigate} to={url_form_to_edit} target="_blank" rel="noopener noreferrer" className='hidden'></Link>

                <div className='absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0'></div>
            </div>

            { /* Backdrop screen for loading */ }
            <ModalWaiting isShow={loading_screen}/>

            <Modal_record_preview 
            isShow={show_modal_preview} 
            detail={detail_modal_preview} 
            detail_dietary={detail_modal_preview_dietary}
            handle_cancel={() => setShow_modal_preview(false)}
            handle_submit={(recordID) => {
                if(recordID) {
                    if(recordID.length > 0) {
                        setUrl_form_to_edit('/staff/form/opd/add/' + name + '/' + recordID);
                    }
                }
            }}/>
        </>
    )
}

export default Form_OPD_dashboard