export const PAL_options = [
    {
      option: 'ไม่นำมาคำนวณ (PAL 1.0)',
      value: 1,
    },
    {
      option: 'กิจกรรมออกแรงเบามาก/นอนติดเตียง (PAL 1.21)',
      value: 1.21,
    },
    {
      option: 'กิจกรรมเบา (PAL 1.4)',
      value: 1.4,
    },
    {
      option: 'กิจกรรมปานกลาง (PAL 1.7)',
      value: 1.7,
    },
    {
      option: 'กิจกรรมหนัก (PAL 2.0)',
      value: 2,
    },
]